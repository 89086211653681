
import Vue, {PropType} from "vue";
import {CategoryNode, ContentNode, NavigationController} from "../../model/product/NavigationController";
import {ProductModelFactory} from "../../model/product/ProductModelFactory";

export default Vue.extend({
  name: "ProductTiles",
  props: {
    contentNode: {
      type: Object as PropType<CategoryNode>,
      default: () => ProductModelFactory.createEmptyCategoryNode(),
      required: true,
    },
  },
  methods: {
    hasContent(c: ContentNode): boolean {
      return NavigationController.hasContent(this, c as CategoryNode);
    },
    url(node: ContentNode) {
      return NavigationController.toURL(node);
    },
    getNodeImage(node: ContentNode): string {
      if (node.imageFileName == null) {
        return "";
      }
      return `${this.$axios.defaults.baseURL}/api/media/${node.imageFileName}`;
    },
    getNodeAltText(node: ContentNode) {
      if (node.imageFileName == null) {
        return node.name;
      }
      return node.imageFileName;
    },
    getKey(node: ContentNode) {
      return NavigationController.getUniqueId(node as CategoryNode);
    },
    hasLongWord(nodeName: string): boolean {
      // Currently css text level 4 is not yet fully implemented. It allows configuration of hyphenation settings
      // Once this standard is fully implemented, this kind of special code can be removed.
      const parts = nodeName.split(" ");
      const letterCount = 12;
      for (let i = 0; i < parts.length; ++i) {
        if (parts[i].length > letterCount) {
          return true;
        }
      }
      return false;
    },
    isLongerThanRowCount(nodeName: string, rowCount: number) {
      const lettersPerRow = 12;
      return nodeName.length > lettersPerRow * rowCount;
    },
    getTitleClasses(node: ContentNode): any {
      const hasLongWord = this.hasLongWord(node.name!);
      const isLongerThanOneRows = this.isLongerThanRowCount(node.name!, 1);
      return {
        hyphenated: hasLongWord,
        "long-text": isLongerThanOneRows,
      };
    },
  },
});
