<template>
  <b-carousel
    id="header-carousel"
    indicators
    slide
    :img-width="100"
    :img-height="100"
    :interval="6000"
  >
    <div class="carousel-item">
      <img
        src="~/assets/images/news/Sitzpergola.jpg"
        alt="Die Spezialanfertigung aus Holz beinhaltet vier grosse Blumenkästen mit einer Pergola und drei Sitzbänken."
        class="carousel-background-image"
        style="object-position: 50% 70%;"
      >
      <div class="teaser pack-and-reverse">
        <div class="carousel-caption">
          <h3 class="caption small-font">Unsere neueste Spezialanfertigung: Eine gemütliche Sitzgelegenheit in Aarau</h3>
          <br>
          <b-link class="btn btn-secondary" href="/news">➜ zu unseren News</b-link>
        </div>
        <img
          class="invisible"
          src="~/assets/images/header/ameise.svg"
          alt="Stark und fleissig wie eine Ameise."
        >
      </div>
    </div>
    <div class="carousel-item">
      <img
        v-if="isSummer"
        class="carousel-background-image"
        src="~/assets/images/BodmerAG_2.jpg"
        alt="Bodmer AG zu jeder Jahreszeit."
        style="object-position: 30%;"
      >
      <img
        v-else
        class="carousel-background-image"
        src="~/assets/images/BodmerAG_2.jpg"
        alt="Bodmer AG zu jeder Jahreszeit."
        style="object-position: 30%;"
      >
      <div class="teaser pack-and-reverse">
        <div class="carousel-caption">
          <h3 class="caption">115 Jahre auf dem Buckel und noch keine Schraube locker.</h3>
          <br>
          <b-link class="btn btn-secondary" href="/geschichte">➜ Geschichte</b-link>
        </div>
        <img
          class="invisible"
          src="~/assets/images/header/ameise.svg"
          alt="Stark und fleissig wie eine Ameise."
        >
      </div>
    </div>
    <div class="carousel-item">
      <div class="teaser pack-and-reverse">
        <div class="carousel-caption">
          <h3 class="caption">Gefahrgut, Logistik oder Lagern - mit uns fahren Sie immer gut.</h3>
          <br>
          <b-link class="btn btn-secondary" href="/produkte/dienstleistung">➜ Dienstleistungen</b-link>
        </div>
        <img
          src="~/assets/images/header/krokodil_gefahrgut_vektorisiert.svg"
          alt="Krokodil sicher angeschnallt auf einer Palette."
        >
      </div>
    </div>
    <div class="carousel-item">
      <div class="teaser pack-and-reverse">
        <div class="carousel-caption">
          <h1 class="h3 caption">Kisten und Paletten ganz nach Ihren Wünschen und Massen - in 3D</h1> <!-- h1 for the startpage! -->
          <br>
          <b-link class="btn btn-secondary" href="/products/e_PAL_001_a_0006">➜ Konfigurieren</b-link>
        </div>
        <img
          src="~/assets/images/header/palette_konfigurierbar_vektorisiert.svg"
          alt="Skizzenhafte Palette die beliebig in vergrössert werden kann. Bei Bodmer erhalten Sie Spezialmasse für Kisten und Paletten."
        >
      </div>
    </div>
  </b-carousel>
</template>

<script>
export default {
  name: "Carousel",
  computed: {
    isSummer() {
      const today = new Date();
      const septemberMonth = 9 - 1;
      const aprilMonth = 3 - 1;
      return (today.getMonth() < septemberMonth && today.getMonth() > aprilMonth);
    },
  },
};
</script>
