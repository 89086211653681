<template>
  <main class="container">
    <Carousel/>
    <b-row>
      <b-col cols="12" class="h-100 content">
        <div
          v-for="node in contentTree.children"
          :key="node.id"
          class="mb-4"
        >
          <h2 class="h1">
            {{ node.name }}
          </h2>
          <product-tiles :content-node="node"/>
        </div>
        <hr>
        <div class="grid-gallery two-columns">
          <b-link href="https://palettenmöbel.ch" class="teaser bodmer-background">
            <h3 class="caption moebel-color">
              Dein Garten ist bereit. Bist du es auch?<br><span
                class="second-title-color"
              >Blumenkisten und Hochbeete</span>
            </h3>
            <div class="floating-teaser-container">
              <img
                src="images/services/Hochbeet.png"
                alt="Hochbeet aus Schweizer Massivholz"
                style="padding-left: 1em;"
              >
              <img
                src="/images/labels/CH_Holz_Bodmer_01.svg"
                alt="Bodmer Label: Nachhaltiges Schweizer Holz"
                class="floating-teaser-image-label"
              >
            </div>
            <div class="moebel-bottom-signature">
              <img src="~/assets/images/moebel/Palettenblock.svg" alt="Palettenmöbel Logo" class="moebel-logo">
              <div class="green-border"/>
            </div>
          </b-link>
          <b-link href="/produkte/dienstleistung" class="teaser">
            <h3 class="caption">
              Wir verpacken alles. Sicher.<br>
              Versprochen.
            </h3>
            <img
              src="~/assets/images/dienstleistungen/verpacken/verpacken.svg"
              alt="Skizze von einem grossen Eifelturm-Model das auf individuell gebauten Stützen gelagert wird für den Transport in einem Container."
              style="min-width: fit-content;"
            >
            <span class="arrow-link">➜ Unsere Dienstleisungen</span>
          </b-link>
        </div>
        <hr>
        <div class="grid-gallery">
          <div class="span-full-column">
            <b-link to="/labels">
              <img src="/images/labels/Labels.jpg" alt="Labels: HPE, IPPC, VHPI ASPV, Holz CH">
            </b-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import {groupBy} from "lodash";
import Carousel from "../../components/home/Carousel";
import * as UiUtil from "../../util/ui/ui-util";
import * as OrmConstants from "../../model/orm-constants";
import ProductTiles from "@/components/product/ProductTiles";

export default {
  components: {
    ProductTiles,
    Carousel,
  },
  asyncData(context) {
    try {
      const productTypes = [];
      const category2ProductTypes = groupBy(productTypes, OrmConstants.productType.categoryId);
      return {
        category2ProductTypes,
      };
    } catch (e) {
      UiUtil.redirectError(context, e);
    }
  },
  data() {
    return {
      userText: this.$auth.isLoggedIn(), // this.loggedIn ? `${this.$auth()} ${this.user.lastName}` : `${this.$auth()}`
    };
  },
  computed: {
    contentTree() {
      return this.$store.getters["navigation/contentTree"];
    },
    categories() {
      return this.$store.getters["navigation/categories"];
    },
  },
};
</script>
